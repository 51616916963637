import { MembershipLevelOffer } from '@aaa/emember/store-price-offers';
import { MembershipOffering, MembershipOfferItem } from '../store/price-offers/helpers/types';
import { MembershipLevel } from '../store/price-offers/helpers/membership-levels';

export function findLevelOfferAmount(
  level: MembershipLevel | undefined | null,
  levelOffers: MembershipLevelOffer | null,
  offering: MembershipOffering,
) {
  const offer =
    levelOffers &&
    level &&
    (levelOffers[level.membershipType]?.find((o) => o.offering === offering) as MembershipOfferItem);

  return offer ? '$' + offer.amount.toFixed(2) : '';
}

import { inject, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import firebase from 'firebase/compat/app';
import { LOCATION } from '@ng-web-apis/common';
import { AnalyticsPageViewEvent } from '../../../../types/analytics-page-view-event';
import { AnalyticsPurchaseEvent, AnalyticsRemoveAREvent } from '../../../../types/analytics-purchase-event';
import { AppAnalyticsEvents } from '../../../../types/analytics-events';

@Injectable({ providedIn: 'root' })
export class DataLayerService {
  analytics = inject(AngularFireAnalytics);
  location = inject(LOCATION);

  viewedPage(eventParams: AnalyticsPageViewEvent['eventParams']) {
    return this.analytics.logEvent(firebase.analytics.EventName.PAGE_VIEW, {
      ...eventParams,
      page_location: this.location.search,
      page_path: eventParams?.page_path,
    });
  }

  purchaseEvent(eventParams: AnalyticsPurchaseEvent['eventParams']) {
    const context = eventParams?.context || '';
    const eventName = context.split(' ')[1];

    if (context.indexOf(AppAnalyticsEvents.JoinNew) !== -1 || context.indexOf(AppAnalyticsEvents.GiftNew) !== -1) {
      this.analytics.logEvent('purchaseNew', eventParams);
    }

    if (eventName) {
      this.analytics.logEvent(String(eventName).trim(), eventParams);
    }

    return this.analytics.logEvent(firebase.analytics.EventName.PURCHASE, eventParams);
  }

  removeAutoRenew(params?: AnalyticsRemoveAREvent['eventParams']) {
    return this.analytics.logEvent(AppAnalyticsEvents.RemoveAR, params);
  }
}

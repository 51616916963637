import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ReactiveFormsModule,
  NG_VALUE_ACCESSOR,
  ControlContainer,
  FormControl,
  ControlValueAccessor,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'ava-form-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckBoxComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true,
  template: `
    <fieldset>
      <legend class="flex -md:flex-col gap-2 m-0">
        <label
          class="cursor-pointer flex gap-2 relative p-0 items-center text-base font-normal focus-visible:outline focus-visible:outline-offset-2
        focus-visible:outline-2
        focus-visible:outline-blue-light {{
            isDisabled || formControl.disabled ? 'opacity-70 cursor-not-allowed' : ''
          }}"
          tabindex="0"
          [class.is-disabled]="isDisabled || formControl.disabled"
          [attr.for]="id"
          (keydown.space)="enterOrSpaceClicked()"
          (keydown.enter)="enterOrSpaceClicked()"
          (click)="changeValue()"
        >
          <input class="hidden peer" type="checkbox" [formControl]="formControl" [attr.id]="id" />
          <span
            class="
              relative
              cursor-pointer
              inline-blocks
              w-4

              before:content-['']
              before:-my-2
              before:absolute
              before:w-4
              before:h-4
              before:inline-block
              before:border-[1px]
              before:bg-white
              before:border-blue-900

              after:content-['']
              after:absolute
              after:mt-[-0.3rem]
              after:ml-[0.3rem]
              after:bg-transparent
              peer-checked:after:border-blue
              after:w-[0.4rem]
              after:h-[0.6rem]
              after:border-solid
              after:border-r-2
              after:border-b-2
              after:border-l-0
              after:border-t-0
              after:border-transparent
              after:rotate-45
            "
          ></span>
          {{ formLabel }}
          <ng-content></ng-content>
        </label>
      </legend>
    </fieldset>
  `,
})
export class FormCheckBoxComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  container = inject(ControlContainer);
  @Input() formLabel = '';
  @Input() formControlName = '';
  @Input() isDisabled = false;
  @Output() changed = new EventEmitter();
  alive$ = new Subject();
  id = 'check-' + Math.random().toString(36).substr(2, 10);
  get formControl() {
    return this.container.control?.get(this.formControlName) as FormControl;
  }

  ngAfterViewInit() {
    this.setDisable(this.isDisabled);
  }

  changeFn: (value: boolean) => void = (value) => {};
  touchFn: (value: boolean) => void = (value) => {};

  enterOrSpaceClicked() {
    const isDisabled = this.isDisabled || this.formControl.disabled;

    if (!isDisabled) {
      this.formControl.setValue(!this.formControl.value);
      const interval = setTimeout(() => {
        this.changed.emit(this.formControl.value);
        clearInterval(interval);
      });
    }
  }

  changeValue() {
    const isDisabled = this.isDisabled || this.formControl.disabled;

    if (!isDisabled) {
      const interval = setTimeout(() => {
        this.changed.emit(this.formControl.value);
        clearInterval(interval);
      });
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.setDisable(isDisabled);
  }

  registerOnChange(fn: any) {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.touchFn = fn;
  }

  writeValue(value: boolean) {
    if (value !== this.formControl.value) {
      this.formControl.setValue(value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) {
      this.setDisable(this.isDisabled);
    }
  }

  setDisable(isDisabled: boolean) {
    if (this.formControl.enabled && isDisabled) {
      this.formControl.disable({ onlySelf: true, emitEvent: false });
    } else if (this.formControl.disabled && !isDisabled) {
      this.formControl.enable({ onlySelf: true, emitEvent: false });
    }
  }
}

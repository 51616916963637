import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, of, switchMap, withLatestFrom } from 'rxjs';
import { MembershipActions } from './membership.actions';
import { Store } from '@ngrx/store';
import { catchError, tap } from 'rxjs/operators';
import { M } from '../m.type';
import { ExecuteService } from '../services/execute.service';
import {
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  ThreePointAuth,
} from '@aaa/interface-joinRenew-membership-membershipM';
import { ClubApp } from '@aaa/emember/types';
import { MembershipLoginForm } from './membership.models';
import { Router } from '@angular/router';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { getThreePointAuth } from '../account/account.selectors';
import { checkMembershipErrorsMSystem } from '../check-membership-errors-m-system';

@Injectable({ providedIn: 'root' })
export class MembershipMSystemEffects {
  store = inject(Store);
  router = inject(Router);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northampton, ClubApp.Shelby]));
  executeService = inject(ExecuteService);

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipActions.logout),
        tap(() => this.router.navigate(['login'])),
      ),
    { dispatch: false },
  );

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.load),
      withLatestFrom(this.store.select(getThreePointAuth)),
      switchMap(([{ membershipNumber }, threePointAuth]) =>
        this.loadMembership(membershipNumber, threePointAuth).pipe(
          map((accountDetails) => MembershipActions.loadSucceeded({ accountDetails })),
          catchError((error) => of(MembershipActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  loginMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.login),
      switchMap(({ payload }) =>
        this.loginMembership(payload).pipe(
          map((accountDetails) => MembershipActions.loginSucceeded({ accountDetails })),
          catchError((error) => of(MembershipActions.loginFailed({ error }))),
        ),
      ),
    ),
  );

  redirectAfterLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipActions.loginSucceeded),
        tap(() => this.router.navigate(['account/membership'])),
      ),
    { dispatch: false },
  );

  loadMembership(membershipNumber: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      membershipNumber,
      method: MembershipMMethod.GET_MEMBER_INFO,
      threePointAuth,
    };

    return this.executeService.membershipQuery<M.MemberInfoResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.meta?.isError;

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject);
        }

        const memberInfo = validateObject.response.memberInfoResponse.Result.Membership[0];
        const offerings = validateObject.response.memberInfoResponse.Result.MembershipOffering;
        const accountDetails = new M.AccountInfo(memberInfo, offerings);

        return accountDetails;
      }),
      delay(1000),
    );
  }

  loginMembership({ membershipNumber, lastName, zipcode }: MembershipLoginForm) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      membershipNumber,
      method: MembershipMMethod.GET_MEMBER_INFO,
      threePointAuth: { zip: zipcode, lastName: lastName, memberNumber: membershipNumber },
    };

    return this.executeService.membershipQuery<M.MemberInfoResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.meta?.isError;

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject);
        }

        const memberInfo = validateObject.response.memberInfoResponse.Result.Membership[0];

        return new M.AccountInfo(memberInfo);
      }),
    );
  }
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap, tap } from 'rxjs';
import { MembershipActions } from './membership.actions';
import { Store } from '@ngrx/store';
import {
  MembershipMzpLookupMembershipEventPayload,
  MembershipMzpMethod,
} from '@aaa/interface-joinRenew-membership-membershipMzp';
import { catchError } from 'rxjs/operators';
import { getResponseKey } from '../utils/get-response-key';
import { Mzp } from '../mzp.type';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { LocationRefService } from '../../modules/share/services/location-ref.service';
import { RequestError, RequestErrorType } from '../generic-errors';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { getSaveCacheSettings } from '../utils/get-cache-settings';

@Injectable({ providedIn: 'root' })
export class MembershipMzpSystemEffects {
  store = inject(Store);
  executeService = inject(ExecuteService);
  location = inject(LocationRefService);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.MidStates]));

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipActions.logout),
        tap(() => (this.location.nativeLocation.href = this.location.nativeLocation.origin + '/account/logout')),
      ),
    { dispatch: false },
  );

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipActions.load),
      switchMap(({ membershipNumber }) =>
        this.loadMembership(membershipNumber).pipe(
          map((accountDetails) => MembershipActions.loadSucceeded({ accountDetails })),
          catchError((error) => of(MembershipActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  loadMembership(membershipNumber: string) {
    const responseKey = getResponseKey();
    const payload: MembershipMzpLookupMembershipEventPayload = {
      cacheSettings: getSaveCacheSettings(membershipNumber),
      memberNumber: String(membershipNumber).substr(6, 7),
      method: MembershipMzpMethod.LOOKUP_MEMBERSHIP,
      responseKey: responseKey,
    };

    return this.executeService.membershipQuery<Mzp.MemberLookupResponseObject>(payload).pipe(
      map((memberLookupResponseObject) => {
        if (memberLookupResponseObject.error) {
          throw new RequestError(RequestErrorType.MembershipError, memberLookupResponseObject);
        }
        const memberLookupResponse = memberLookupResponseObject.response;

        const accountDetails = new Mzp.AccountInfo(memberLookupResponse.memberInfo);

        // Todo: for mzp need to find a way that API to send full membership number
        accountDetails.memberNumber = membershipNumber;

        return accountDetails;
      }),
    );
  }
}

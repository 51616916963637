import { ClubApp } from '@aaa/emember/types';
import { environment } from '../../../../environments/environment';

export type MembershipLevel = {
  name: string;
  level: string; //  used in the query parameter
  rv: boolean;
  membershipType: string;
  towMiles?: number;
};

const membershipLevels: Partial<{ [key in ClubApp]: MembershipLevel[] }> = {
  [ClubApp.Hoosier]: [
    {
      rv: false,
      name: 'Classic',
      level: 'CLASSIC',
      membershipType: 'B',
      towMiles: 3,
    },
    {
      rv: false,
      name: 'Plus',
      level: 'PLUS',
      membershipType: 'P',
      towMiles: 100,
    },
    {
      rv: true,
      name: 'Plus RV',
      level: 'PLUS-RV',
      membershipType: 'R',
      towMiles: 100,
    },
    {
      rv: false,
      name: 'Premier',
      level: 'PREMIER',
      membershipType: 'R',
      towMiles: 200,
    },
  ],
  [ClubApp.MidStates]: [
    {
      rv: false,
      name: 'Basic',
      level: 'BASIC',
      membershipType: 'BS',
      towMiles: 3,
    },
    {
      rv: false,
      name: 'Plus',
      level: 'PLUS',
      membershipType: 'PL',
      towMiles: 100,
    },
    {
      rv: true,
      name: 'Plus RV',
      level: 'PLUS-RV',
      membershipType: 'RV',
      towMiles: 100,
    },
    {
      rv: false,
      name: 'Premier',
      level: 'PREMIER',
      membershipType: 'PM',
      towMiles: 200,
    },
    {
      rv: true,
      name: 'Premier RV',
      level: 'PREMIER-RV',
      membershipType: 'PR',
      towMiles: 200,
    },
  ],
  [ClubApp.SouthJersey]: [
    {
      rv: false,
      name: 'Basic',
      level: 'BASIC',
      membershipType: 'B',
      towMiles: 3,
    },
    {
      rv: false,
      name: 'Plus',
      level: 'PLUS',
      membershipType: 'P',
      towMiles: 100,
    },
    {
      rv: true,
      name: 'Plus RV',
      level: 'PLUS-RV',
      membershipType: 'R',
      towMiles: 100,
    },
    {
      rv: false,
      name: 'Premier',
      level: 'PREMIER',
      membershipType: 'E',
      towMiles: 200,
    },
  ],
  [ClubApp.Northampton]: [
    {
      rv: false,
      name: 'Basic',
      level: 'BASIC',
      membershipType: 'B',
      towMiles: 3,
    },
    {
      rv: false,
      name: 'Plus',
      level: 'PLUS',
      membershipType: 'P',
      towMiles: 100,
    },
    {
      rv: true,
      name: 'Plus RV',
      level: 'PLUS-RV',
      membershipType: 'R',
      towMiles: 100,
    },
    {
      rv: false,
      name: 'Premier',
      level: 'PREMIER',
      membershipType: 'E',
      towMiles: 200,
    },
    {
      rv: true,
      name: 'Premier RV',
      level: 'PREMIER-RV',
      membershipType: 'V',
      towMiles: 200,
    },
  ],
  [ClubApp.Northway]: [
    {
      rv: false,
      name: 'Basic',
      level: 'BASIC',
      membershipType: 'B',
      towMiles: 3,
    },
    {
      rv: false,
      name: 'Plus',
      level: 'PLUS',
      membershipType: 'P',
      towMiles: 100,
    },
    {
      rv: true,
      name: 'Plus RV',
      level: 'PLUS-RV',
      membershipType: 'R',
      towMiles: 100,
    },
    {
      rv: false,
      name: 'Premier',
      level: 'PREMIER',
      membershipType: 'E',
      towMiles: 200,
    },
    {
      rv: true,
      name: 'Premier RV',
      level: 'PREMIER-RV',
      membershipType: 'V',
      towMiles: 200,
    },
  ],
  [ClubApp.Shelby]: [
    {
      rv: false,
      name: 'Basic',
      level: 'BASIC',
      membershipType: 'B',
      towMiles: 3,
    },
    {
      rv: false,
      name: 'Plus',
      level: 'PLUS',
      membershipType: 'P',
      towMiles: 100,
    },
    {
      rv: true,
      name: 'Plus RV',
      level: 'PLUS-RV',
      membershipType: 'R',
      towMiles: 100,
    },
  ],
};

export function getMembershipLevels() {
  const levels = membershipLevels[environment.clubId];

  return levels ? levels : [];
}

export function findMembershipLevelByMembershipType(membershipType?: string) {
  if (!membershipType) {
    return null;
  }

  const levels = getMembershipLevels();

  return levels.find((m) => m.membershipType === membershipType) || null;
}

export function getWithoutRvMembershipLevels() {
  const levels = membershipLevels[environment.clubId];

  return levels ? levels.filter((l) => !l.rv) : [];
}

export function findMembershipTypeByLevel<T = string>(level?: string, rv = false): T {
  if (!level) return '' as T;

  const levels = getMembershipLevels();
  const foundLevel = levels.find((m) => m.level === level && m.rv === rv);

  if (!foundLevel) {
    return '' as T;
  }

  return foundLevel.membershipType as T;
}

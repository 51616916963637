import { ComparePlanItem } from '../../../types/compare-plan';
import { MembershipLevelOffer } from '@aaa/emember/store-price-offers';
import { findLevelOfferAmount } from '../utils';
import { findMembershipLevelByMembershipType } from '../../store/price-offers/helpers/membership-levels';

export const compareCharts = (levelOffers: MembershipLevelOffer | null): ComparePlanItem[][] => {
  const basicLevel = findMembershipLevelByMembershipType('B');
  const plusLevel = findMembershipLevelByMembershipType('P');
  const plusRVLevel = findMembershipLevelByMembershipType('R');

  return [
    [
      {
        title: 'Get RV and Motorcycle coverage',
        description:
          'Add coverage for Motorcycles, Motor homes, Pickup with camper, Travel trailers, and Camping trailers.',
      },
      {
        description: 'Coverage unavailable for Basic membership levels',
      },
      {
        button: true,
        buttonLabel: 'PLUS RV',
        buttonId: 'plus_rv',
        buttonParam: plusRVLevel?.level ? { membershiplevel: plusRVLevel.level, rv: true } : {},
      },
    ],
    [
      {
        title: 'Associates',
        description:
          "Membership may be extended to anyone living in the primary member's household or to students away at college. Each associate membership provides the same privileges as the primary member and will carry their own card.",
      },
      {
        description: `
        ${findLevelOfferAmount(basicLevel, levelOffers, 'associate')} each`,
      },
      {
        description: `${findLevelOfferAmount(plusLevel, levelOffers, 'associate')} each
        <br/>
        <i>$10 increase for family plan will be added to the primary membership.</i>`,
      },
    ],
    [
      {
        title: 'Towing Coverage',
        description:
          'When you need us most - get reliable 24/7 roadside assistance, available throughout the USA and Canada.',
      },
      {
        description: basicLevel?.level ? `Up to ${basicLevel.towMiles} miles` : '',
      },
      {
        description: plusLevel?.level ? `Up to ${plusLevel.towMiles} miles` : '',
      },
    ],
    [
      {
        title: 'Number of Service Calls',
        description:
          'Need a tow or other service call? The primary member and each associate on the account can receive up to the alloted number of service calls per membership year.',
      },
      {
        description: '4 calls',
      },
      {
        description: `4 calls`,
      },
    ],
    [
      {
        title: 'Emergency Fuel Delivery',
        description:
          'When a vehicle runs out of fuel, a service technician will bring enough fuel to reach the nearest service station, or the vehicle will be towed to a facility where fuel is available. AAA cannot guarantee specific brands, octane ratings, or the availability of diesel fuel. If the vehicle will not start after fueling, towing may be provided.',
      },
      {
        description: 'Pay pump price, free delivery',
      },
      {
        description: 'Free fuel, free delivery',
      },
    ],
    [
      {
        title: 'Extrication',
        description:
          'When a vehicle becomes stuck and can be safely reached from a normally traveled roadway, service will be provided using standard automobile servicing equipment. If the vehicle cannot be safely driven after winching, it may be towed. Plus RV coverage may be required, see handbook for restrictions.',
      },
      {
        description: 'One driver/tow truck',
      },
      {
        description: 'Two drivers/two trucks, if needed',
      },
    ],
    [
      {
        title: 'RV Roadside Assistance Coverage',
        description:
          'An extension of AAA Plus. RV is considered to be a motor home, truck with camper, large dual wheel pickup, travel trailer or camping trailer. Restrictions may apply*.',
      },
      {
        description: '',
      },
      {
        description: 'Available at the Plus Level',
      },
    ],
    [
      {
        title: 'Battery Service',
        description:
          'Car battery on-the-spot testing and jumpstart. Battery sales and free installation available in select area.',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'Trip Interruption Coverage',
        description:
          'If you are involved with a collision 100 miles or more from your home, and the mishap is reported to the police, AAA will reimburse up to $1500 per family per collision for any one of the following expenses: 1) rental of a replacement car or 2) local lodging and meals or 3) commercial transportation to destination (or return home). It also covers taxi fare up to $25 when en route to rental car agency or to commercial transportation.**',
      },
      {
        description: '',
      },
      {
        description: 'Up to $700 if vehicle is disabled due to collision',
      },
    ],
    [
      {
        title: 'Passport Photos',
        description:
          'Get your passport photos the easy way. Visit your local AAA service center where we can take your photos and print them right away.',
      },
      {
        description: '$10 per member',
      },
      {
        description: '$10 per member',
      },
    ],
    [
      {
        title: 'Lockout Service',
        description:
          'If you become locked out of your primary residence, you can receive up to $100 in locksmith services per year, per household to gain access to your home from the outside.',
      },
      {
        description: 'Covered up to $50',
      },
      {
        description: 'Covered up to $100',
      },
    ],
    [
      {
        title: 'Tire Service',
        description:
          'If your vehicle’s spare tire is inflated and serviceable, it will be installed to replace a flat tire. If no inflated spare is available, the vehicle will be towed in accordance with the towing provisions.',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'Free Identity Theft Monitoring',
        description: '',
      },
      {
        description: 'With option to purchase upgrade',
      },
      {
        description: 'With option to purchase upgrade',
      },
    ],
  ];
};

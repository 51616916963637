import { MembershipLevelOffer } from '@aaa/emember/store-price-offers';
import { ComparePlanItem } from '../../../types/compare-plan';
import { findLevelOfferAmount } from '../utils';
import { findMembershipLevelByMembershipType } from '../../store/price-offers/helpers/membership-levels';

export const compareCharts = (levelOffers: MembershipLevelOffer | null): ComparePlanItem[][] => {
  const basicLevel = findMembershipLevelByMembershipType('B');
  const plusLevel = findMembershipLevelByMembershipType('P');
  const premierLevel = findMembershipLevelByMembershipType('E');

  return [
    [
      {
        title: 'Add a Family Member',
        description:
          "Membership may be extended to anyone living in the primary member's household. Each associate membership provides the same privileges as the primary member and will carry their own card.",
      },
      { description: `${findLevelOfferAmount(basicLevel, levelOffers, 'associate')}` },
      { description: `${findLevelOfferAmount(plusLevel, levelOffers, 'associate')}` },
      { description: `${findLevelOfferAmount(premierLevel, levelOffers, 'associate')}` },
    ],
    [
      {
        title: 'Towing Coverage',
        description:
          'When you need us most - get reliable 24/7 roadside assistance, available throughout the USA and Canada.',
      },
      {
        description: basicLevel?.level ? `${basicLevel.towMiles} miles` : '',
      },
      {
        description: '100 miles <br> (Services begin 7 days after membership has been processed)',
      },
      {
        description:
          '200 miles x 1 <br> (per household)<br> 100 miles x 3 <br>(Services begin 7 days after membership has been processed)',
      },
    ],
    [
      {
        title: 'Number of Service Calls',
        description:
          'The primary member and each associate on the account can receive up to the allotted number of service calls per membership year.',
      },
      { description: '4 calls' },
      { description: `4 calls` },
      { description: `4 calls` },
    ],
    [
      {
        title: 'Vehicle Lockout / Locksmith Service',
        description:
          'If a key is locked inside a vehicle, lost, or broken, locksmith service is covered up to the benefit limit.',
      },
      { description: '$50 (car only)' },
      { description: '$100 (car only)' },
      { description: '$150 (car only)' },
    ],
    [
      {
        title: 'Emergency Fuel Delivery',
        description:
          'When a vehicle runs out of fuel, a service technician will bring enough fuel to reach the nearest service station, or the vehicle will be towed to a facility where fuel is available. AAA cannot guarantee specific brands, octane ratings, or the availability of diesel fuel. If the vehicle will not start after fueling, towing may be provided.',
      },
      { description: 'FREE delivery' },
      { description: 'FREE fuel and FREE delivery' },
      { description: 'FREE fuel and FREE delivery' },
    ],
    [
      {
        title: 'Extrication',
        description: `When a vehicle becomes stuck and can be safely reached from a normally traveled roadway, service will be provided using standard automobile servicing equipment.
          If the vehicle cannot be safely driven after winching, it may be towed. See <a href="https://www.aaa.com/Terms">AAA.com/Terms</a> for restrictions.`,
      },
      { description: 'One driver/tow truck' },
      { description: 'Two drivers/two trucks, if needed' },
      { description: 'Two drivers/two trucks, if needed' },
    ],
    [
      {
        title: 'Battery Service',
        description:
          'Car battery on-the-spot testing and jumpstart. Battery sales and free installation available in select areas.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Free Maps, TourBooks and TripTik Routings',
        description:
          'Before you hit the road, put your membership to good use with our extensive travel planning tools.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'AAA Approved Auto Repair',
        description:
          'Put your car in the hands of a AAA Approved Auto Repair facility that guarantees their work so you can get back on the road. Includes 10% member-only discounts on parts and labor at participating shops.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'AAA Discounts & Rewards®',
        description:
          'Members save an average of $200 a year when using AAA Discounts & Rewards® on shopping, dining, entertainment, travel, and more.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Travel Store Discount',
        description: 'Find great deals on name-brand luggage, travel essentials, and more!',
      },
      { description: 'Member pricing' },
      { description: 'Member pricing' },
      { description: 'Member pricing' },
    ],
    [
      {
        title: 'Tire Service',
        description: 'Installation of inflated spare tire.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Mechanical First Aid',
        description: 'Minor or temporary adjustments.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Notary Service',
        description: 'Personal courtesy service provided for AAA members free of charge.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Emergency Check, Credit/Debit Card Acceptance',
        description:
          'If emergency service or repairs are not covered by your AAA membership, show your AAA card and you can pay for the service or the repairs with a personal check or credit/debit card up to $250.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Hotel & Car Rental Discounts',
        description:
          "Get the best rates and exclusive member discounts. AAA hotels are backed by AAA's trademark Diamond ratings.",
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Cruise and Tour Benefits',
        description:
          'When it comes to travel, nobody does it better than AAA. We work with top-notch preferred providers to bring AAA members excellent service, great deals and exclusive member benefits.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Auto / Home Insurance Discounts',
        description:
          'Get quality insurance coverage that fits your lifestyle and budget. Plus, you may receive AAA member savings on your policy.',
      },
      { description: 'Included' },
      { description: 'Included' },
      { description: 'Included' },
    ],
    [
      {
        title: 'Free Battery Replacement Benefit',
        description:
          'Free battery will be installed only upon failure of AAA battery load test, and within the battery warranty period. For restrictions, see <a href="https://www.aaa.com/termsofuse">AAA.com/termsofuse</a>',
      },
      {},
      {},
      { description: 'Included' },
    ],
    [
      {
        title: 'Free Car Rental',
        description:
          'When you need a replacement vehicle due to a mechanical breakdown that results in a tow, AAA can arrange a free Hertz rental car. (Arrangements must be made through AAA.)',
      },
      {},
      {},
      { description: 'Included' },
    ],
    [
      {
        title: 'Ride Assist Transportation',
        description: `If you, as a Premier Member, are involved in an accident or breakdown, a AAA Representative will assist you with making car rental reservations or other transportation arrangements,
          and you will also receive a reimbursement for the first $20 of a cab fare expense.
         For restrictions, see <a href="https://www.aaa.com/termsofuse">AAA.com/termsofuse</a>`,
      },
      {},
      {},
      { description: 'Included' },
    ],
    [
      {
        title: 'Accident Assist',
        description:
          'Receive verbal accident assistance at the time of an accident or breakdown. When the call is placed for Roadside Assistance, a AAA Representative will help by contacting family members, locate restaurants and find hotel accommodations.',
      },
      {},
      {},
      { description: 'Included' },
    ],
  ];
};

import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { concatMap, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WindowRefService } from '../../modules/share/services/window-ref.service';
import { twoDigitMonth } from '../../modules/share/utils/two-digit-month';
import { Flex, FlexMicroform } from '../../../types/payment-flex';

type CybersourceJWT = {
  flx: {
    path: string;
    data: string;
    origin: string;
    jwk: {
      kty: string;
      e: string;
      use: string;
      n: string;
      kid: string;
    };
  };
  ctx: [
    {
      data: {
        clientLibraryIntegrity: string;
        clientLibrary: string;
        allowedCardNetworks: string[];
        targetOrigins: string[];
        mfOrigin: string;
        allowedPaymentTypes: string[];
      };
      type: string;
    },
  ];
  iss: string;
  exp: number;
  iat: number;
  jti: string;
};

export interface FlexFormEvents {
  event: 'numberLoaded' | 'numberChanged' | 'numberBlur' | 'cvvLoaded' | 'cvvChanged' | 'cvvBlur' | 'initialized';
  errors: { [key: string]: boolean } | null;
  options?: {
    cardType: string;
    cardName: string;
  };
}

@Injectable({ providedIn: 'root' })
export class CybersourcePaymentService {
  rendererFactory = inject(RendererFactory2);
  renderer: Renderer2;
  document = inject(DOCUMENT);
  windowRef = inject(WindowRefService);
  flexMicroForm: FlexMicroform | null = null;
  flexForm$: Observable<FlexFormEvents> | null = null;

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init(paymentCardId: string, paymentCvvId: string, token: string) {
    if (!this.flexForm$) {
      return of(null).pipe(
        concatMap(() => this.decodeJwt(token)),
        concatMap((decodedToken) => this.addScript(decodedToken)),
        concatMap(() => {
          this.flexForm$ = this.initFlexForm(paymentCardId, paymentCvvId, token);

          return this.flexForm$;
        }),
      );
    }

    return this.flexForm$;
  }

  decodeJwt(token: string) {
    return new Observable<CybersourceJWT>((observe) => {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));

        if (!decodedToken) {
          observe.error('Invalid decode token microform');
        }

        observe.next(decodedToken as CybersourceJWT);
        observe.complete();
      } catch (e) {
        observe.error('Invalid decode token microform');
      }
    });
  }

  createToken(month: number, year: number) {
    return this.generateTokenFlexForm(month, year);
  }

  generateTokenFlexForm(month: number, year: number): Observable<string> {
    return new Observable((observe) => {
      const options = {
        expirationMonth: twoDigitMonth(month),
        expirationYear: String(year),
      };

      this.flexMicroForm?.createToken(options, (error, token) => {
        if (error) {
          observe.error('The flex form invalid');
        }
        if (!error && token) {
          observe.next(token);
        }
        observe.complete();
      });
    });
  }

  initFlexForm(paymentCardId: string, paymentCvvId: string, token: string): Observable<FlexFormEvents> {
    return new Observable((observe) => {
      if (this.flexMicroForm) {
        observe.next({
          event: 'initialized',
          errors: null,
        });
      }
      try {
        this.flexMicroForm = (new this.windowRef.nativeWindow.Flex(token) as Flex).microform({
          styles: {
            input: {
              'font-size': '16px',
              'font-weight': '200',
              'font-family': 'proxima_nova, sans-serif',
              color: '#313131',
            },
            '::placeholder': {
              color: '#595959',
              opacity: '0.7',
            },
          },
        });
      } catch (err) {
        observe.error('Invalid initialization microform');

        return;
      }

      const cardNumberRef = this.document.querySelector(paymentCardId);
      const cvvFRef = this.document.querySelector(paymentCvvId);

      if (cardNumberRef && cvvFRef) {
        const cardNumberField = this.flexMicroForm.createField('number', {
          placeholder: 'Enter card number',
        });

        cardNumberField.on('blur', () => {
          observe.next({ event: 'numberBlur', errors: null, options: { cardType: '', cardName: '' } });
        });
        cardNumberField.on('load', () => {
          const errors = { required: true };
          observe.next({ event: 'numberLoaded', errors, options: { cardType: '', cardName: '' } });
        });
        cardNumberField.on('change', (data) => {
          const dataCard = data.card.length ? data.card[0] : null;
          let errors = null;
          const cardTypeName = dataCard?.name || '';
          const cardName = dataCard?.brandedName || '';
          const cardType = dataCard?.cybsCardType || '';

          if (!environment.payment.allowCartTypes.includes(cardType)) {
            errors = { ...(errors ? errors : {}), unsupportedCard: true };
          }

          if (!data.valid) {
            errors = { ...(errors ? errors : {}), invalid: true };
          }

          observe.next({ event: 'numberChanged', errors, options: { cardType: cardTypeName, cardName } });
        });

        const cvvField = this.flexMicroForm.createField('securityCode', {
          placeholder: 'CVV',
        });

        cvvField.on('blur', () => {
          observe.next({ event: 'cvvBlur', errors: null, options: { cardType: '', cardName: '' } });
        });
        cvvField.on('change', (data) => {
          const errors = data.valid ? null : { invalid: true };
          observe.next({ event: 'cvvChanged', errors });
        });
        cvvField.on('load', () => {
          const errors = { required: true };
          observe.next({ event: 'cvvLoaded', errors });
        });

        cardNumberField.load(cardNumberRef as any);
        cvvField.load(cvvFRef as any);

        observe.next({
          event: 'initialized',
          errors: null,
        });
      } else {
        observe.error('Invalid paymentCardId or paymentCvvId');
      }
    });
  }

  addScript(cybersourceData: CybersourceJWT) {
    return new Observable((observe) => {
      const { clientLibraryIntegrity, clientLibrary } = cybersourceData.ctx[0].data;
      const existing = this.document.querySelector('[integrity="' + clientLibraryIntegrity + '"]');

      try {
        if (!existing) {
          const script = this.renderer.createElement('script');
          script.integrity = clientLibraryIntegrity;
          script.crossOrigin = 'anonymous';
          script.type = 'text/javascript';
          script.src = clientLibrary;

          const head = this.document.querySelector('head');

          this.renderer.appendChild(head, script);

          script.onload = () => {
            observe.next(script);
            observe.complete();
          };
        } else {
          observe.next(existing);
          observe.complete();
        }
      } catch (err) {
        observe.error(err);
      }
    });
  }
}
